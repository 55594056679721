<!--
 * @Date: 2024-09-03 10:35:01
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-10-12 08:59:43
 * @FilePath: \zyt-mobile-frontend\src\views\ruralFilm\pages\searchPoint\index.vue
-->
<template>
  <div class="search-point">
    <!-- <van-nav-bar title="放映点查询" left-arrow @click-left="onClickLeft" /> -->
    <div class="search-container">
      <div class="tab-bar">
        <van-sidebar v-model="selectedIndex" @change="selectCategory">
          <van-sidebar-item
            v-for="category in categories"
            :key="category.id"
            :title="category.name"
            :value="category.id"
          >
          </van-sidebar-item>
        </van-sidebar>
      </div>
      <div class="point-list">
        <!-- Search bar -->
        <div class="search-bar">
          <van-search
            v-model="queryParam.search"
            size="small"
            placeholder="请输入您查找的放映点"
            show-action
            @search="handleSearch"
          >
            <template #action>
              <span> 搜索 </span>
            </template>
          </van-search>
        </div>
        <!-- List of screening points -->
        <div class="point-list-container">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div
              class="point"
              v-for="(point,index) in selectedCategoryData"
              :key="index"
              @click="toPointDetail(point)"
            >
              <div>
                <h3>{{ point.name }}</h3>
                <p>{{ point.address }}</p>
              </div>
              <div class="point-btn">
                <van-tag
                  type="primary"
                  round
                  v-if="point.showPointType === 1"
                  plain
                  >固定</van-tag
                >
                <van-tag
                  type="danger"
                  round
                  v-if="point.showPointType === 2"
                  plain
                  >流动</van-tag
                >
              </div>
            </div>
          </van-list>
        </div>
        <van-empty
          v-if="!selectedCategoryData.length"
          description="暂无放映点"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/api'
import { SetPvAndUv } from '@/mixins/PvAndUv'
export default {
  mixins: [SetPvAndUv],
  data() {
    return {
      selectedIndex: 0,
      loading: false,
      finished: false,
      total: 0,
      categories: [
        // Add more categories as needed
      ],
      selectedCategoryData: [],
      queryParam: {
        pageSize: 10,
        pageNum: 1,
        regionAreaId: null,
        search: '',
      },
    }
  },
  mounted() {
    this.getCityList()
  },
  methods: {
    // 获取城市列表
    async getCityList() {
      const res = await api.getCityList()
      this.categories = res
      this.queryParam.regionAreaId = this.categories[0].id
      this.selectCategory(0)
    },
    selectCategory(index) {
      console.log({ index }, this.categories[index])
      this.queryParam.regionAreaId = this.categories[index].id
      this.selectedCategoryData = []
      this.loading = true
      this.finished = false
      this.queryParam.pageNum = 0
      this.onLoad()
    },
    handleSearch(){
      this.queryParam.pageNum = 0
      this.selectedCategoryData = []
      this.loading = true
      this.finished = false
      this.onLoad()
    },
    onLoad() {
      this.queryParam.pageNum++
      api.getShowPointsPage(this.queryParam).then((res) => {
        const { success, result } = res
        if (success) {
          const { pages, records, total, size } = result
          this.total = total
          console.log({ pages, records, total })
          const _this = this
          _this.selectedCategoryData.push(...records)
          this.loading = false
          this.finished = this.selectedCategoryData.length >= total
          console.log({ pages, size, total })
        }
      })
    },
    onClickLeft() {
      this.$router.back()
    },
    // 跳转到放映点详情页面
    toPointDetail(point) {
      this.$router.push({
        path: '/ruralFilm/pointDetail/' + point.id,
        // query: { pointId: point.id },
      })
    },
  },
}
</script>

<style scoped lang="less">
/* Add your custom styles here */
::v-deep .van-sidebar {
  width: auto;
}
::v-deep .van-sidebar-item--select {
  background-color: transparent;
  &::before {
    background-color: @color-primary;
  }
}
.search-bar {
  /* Add styles for the search point container */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.search-container {
  /* Add styles for the search container */
  display: flex;
  margin-top: 16px;

  .tab-bar {
    width: 30%;
    /* Add additional styles for the tab bar */
  }

  .point-list {
    width: 68%;
    /* Add additional styles for the point list */
    &-container {
      max-height: 90vh;
      overflow-y: auto;
    }
    .point {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 54px;
      padding: 0 10px;
      margin-top: 10px;

      div {
        &:first-child {
          width: 80%;
        }
      }
      h3 {
        font-size: @font-size-large;
        color: #363a44;
        padding: 0;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      p {
        font-size: @font-size-small;
        color: #b3b5b9;
        padding: 0;
        margin: 0;
      }
    }
  }
}
</style>
